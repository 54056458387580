<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import axios from "axios";
import { authHeader } from "../../../helpers/auth-header";

import ConfirmationModal from "@/components/modals/confirmation-modal";
import MessageModal from "@/components/modals/message-modal";

import InputGroup from "@/components/newforms/input-group";
import SelectGroup from "@/components/newforms/select-group";

import SaveButton from "@/components/buttons/save-button";

/**
 * Orders Component
 */
export default {
    components: {
        Layout,
        PageHeader,
        ConfirmationModal,
        MessageModal,
        InputGroup,
        SelectGroup,
        SaveButton,
    },
    data() {
        const id = this.$route.params.id;
        const modeName = id === 'add' ? 'Add' : 'Edit';

        return {
            id: id,
            title: `Agency ${modeName}`,
            modeName,
            items: [
                {
                    text: "Agency"
                },
                {
                    text: modeName,
                    active: true,
                },
            ],
            data: {
                agency_name: '',
                is_verified: 1,
            },
            verify_options: [
                { text: 'Verified', value: 1 },
                { text: 'Not Verified', value: 0 },
            ],
            errors: {},
            redirect_page: false,
            redirect_to: "",
            button_click: false
            // statusOptions: [
            //     { name: 'Active', value: 1 },
            //     { name: 'Inactive', value: 0 },
            // ],
        };
    },
    computed: {

    },
    async mounted() {
        if (this.modeName !== 'Add') {
            const result = await axios.get(`${process.env.VUE_APP_APIURL}/agency/${this.id}`, {
                headers: authHeader(),
            });
            if (result && result.data['code'] === 'success') {
                const data = result.data['data'];
                for (const [key, item] of Object.entries(data)) {
                    if (key in this.data) {
                        this.data[key] = item;
                    }
                }

            }
        }
        
    },
    methods: {
        async save() {
            if (this.button_click) return ;
            this.button_click = true;
            // console.log(this.id);
            this.errors = {};
            let result;
            if (this.modeName === 'Add') {
                const requestData = this.data;
                result = await axios.post(`${process.env.VUE_APP_APIURL}/agency`, requestData, {
                    headers: authHeader(),
                });
            } else {
                const requestData = this.data;
                result = await axios.put(`${process.env.VUE_APP_APIURL}/agency/${this.id}`, requestData, {
                    headers: authHeader(),
                });
            }

            if (result) {
                this.button_click = false;
                if (result.data['code'] === 'success') {
                    if (this.modeName === 'Add') {
                        this.$refs.messageModal.showModal('Your record has been added successfully');
                        this.redirect_to = {name: "Agency View", params: {id: result.data.data.id}};
                        this.redirect_page = true;
                    } else {
                        this.$refs.messageModal.showModal('Your record has been updated successfully');
                        this.redirect_to = {name: "Agency View", params: {id: result.data.data.id}};
                        this.redirect_page = true;
                    }
                } else if (result.data['code'] === 'permission_denied') {
                    this.$refs.messageModal.showModal('Permission Denied');
                } else if (result.data['code'] === 'invalid_field') {
                    this.errors = result.data['errors'];
                }
            }
        },
        redirect() {
            if (this.redirect_page && this.redirect_to != '')
                this.$router.push(this.redirect_to);
        }
    }
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <ConfirmationModal ref="confirmationModal" />
        <MessageModal ref="messageModal" @CloseBtn="redirect" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card" style="border-radius: 15px;">
                    <div class="card-body">
                        <div class="row mt-2">
                            <div class="col-12 col-md-4 mb-2">
                                <InputGroup id="agency_name" name="agency_name" v-model="data.agency_name" label="Agency Name" placeholder="Agency Name" :error="errors.agency_name"/>
                            </div>
                            <div class="col-12 col-md-4 mb-2">
                                <SelectGroup id="is_verified" name="is_verified" v-model="data.is_verified" label="Is Verified" placeholder="Is Verified" :options="verify_options" :error="errors.is_verified"/>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-12">
                                <SaveButton @click="save" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</Layout></template>