<script>
export default {
    props: {
        disabled: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {

        };
    },

    created() {

    },

    methods: {

    }
};
</script>

<template>
    <a class="btn btn-orange mb-2" :class="disabled ? 'btn-disabled': ''" @click="$emit('click')">
        <i class="mdi mdi-plus-thick"></i> Save
    </a>
</template>